// Rails default includes
import "../lib/_jquery.js"
import './shared/log-override'
import Rails from '@rails/ujs'
import 'bootstrap'
import '@fortawesome/fontawesome-free'

import ElectionApp from '../components/frontend/voting/App.vue'
import SignUpApp from '../components/frontend/sign_up/App.vue'

import signUpStore from './frontend/signUpStore';
import store from './frontend/store.js'
import votingModule from "./frontend/votingModule";
import i18n from "./shared/i18n";
import createPersistedState from 'vuex-persistedstate'
import bsTooltip from './shared/bs-tooltips' // Instantiate the code
import { createApp, configureCompat } from 'vue'
import * as UILibrary from "@assemblyvoting/ui-library"; // Imports components
import "@assemblyvoting/ui-library/styles"; // Imports styles

if (!window._rails_loaded) Rails.start();

// Hide warnings coming from the actioncable-vue module.
configureCompat({
  OPTIONS_BEFORE_DESTROY: 'suppress-warning',
  CUSTOM_DIR: 'suppress-warning',
  ATTR_FALSE_VALUE: 'suppress-warning',
  RENDER_FUNCTION: 'suppress-warning',
});

// Create our persisted state with paths for what to save and where to save it.
const storedState = createPersistedState({
  storage: window.sessionStorage,
  paths: ['voting', 'currentLocale', 'visibleTab']
})


// unhandled rejections (we use this as a global event handler)
window.onunhandledrejection = event => {
  let error = event.reason;
  if(!error.response){
    // display connection warning
    store.commit('connectionFailed')
    event.preventDefault()
  } else if(error.response.status === 401 && [1, 2, 3].includes(error.response.data.code)) { // [1: sessionExpired, 2: sessionNotFound, 3: voterNotFound]
    // logout voter
    store.dispatch('voting/destroySession')
    event.preventDefault()
  }
}

// Register voting module
store.registerModule('voting', votingModule)

// Run storedState with store as input after the voting module is registered
// IMPORTANT for it to NOT override voting module on page refresh
storedState(store)


document.addEventListener('DOMContentLoaded', () => {

  let electionApp = document.getElementById('election-app')
  if( electionApp ){
    let election = JSON.parse(electionApp.getAttribute('data-election'))
    let electionUrl = electionApp.getAttribute('data-url')
    let electionLocales = JSON.parse(electionApp.getAttribute('data-locales'))
    let partnerBranding = JSON.parse(electionApp.getAttribute('data-partnerBranding'))
    let organisationBranding = JSON.parse(electionApp.getAttribute('data-organisationBranding'))

    store.commit('setPartnerBranding', partnerBranding)
    store.commit('setOrganisationBranding', organisationBranding)
    store.commit('setElectionUrl', { electionUrl })
    store.commit('setLocales', electionLocales)
    store.commit('setLocale', i18n.locale)
    store.commit('setElection', { ...election, userModules: {} })

    i18n.global.locale = store.state.currentLocale

    const app = createApp(ElectionApp)

    app.use(store)
    app.use(i18n)
    app.use(UILibrary.default, i18n)
    app.mount("#election-app");
  }

  let signUpApp = document.getElementById('sign-up-app')
  if( signUpApp ){
    storedState(signUpStore)
    let election = JSON.parse(signUpApp.getAttribute('data-election'))
    let electionUrl = signUpApp.getAttribute('data-url')
    let electionLocales = JSON.parse(signUpApp.getAttribute('data-locales'))
    let partnerBranding = JSON.parse(signUpApp.getAttribute('data-partnerBranding'))
    let organisationBranding = JSON.parse(signUpApp.getAttribute('data-organisationBranding'))

    signUpStore.commit('setPartnerBranding', partnerBranding)
    signUpStore.commit('setOrganisationBranding', organisationBranding)
    signUpStore.commit('setElectionUrl', { electionUrl })
    signUpStore.commit('setLocales', electionLocales)
    signUpStore.commit('setLocale', i18n.locale)
    signUpStore.commit('setElection', election)

    i18n.locale = signUpStore.state.currentLocale


    const app = createApp(SignUpApp)
    app.use(signUpStore)
    app.use(i18n)
    app.use(UILibrary.default, i18n)
    app.mount("#sign-up-app");
  }

})

$(function () {
  $('#compatibilityModal').modal('show')
})

$(function(){
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // We listen to the resize event
  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
})
